<!-- 线下招聘会 -->
<template>
  <div class="job_fair_new">
    <header>
      <p>|&nbsp;招聘会列表</p>
      <Button type="warning" @click="select">已预定的招聘会</Button>

    </header>
    <section>
      <ul>
        <li v-for="(item,index) in job_fair_list" :key="item._id">
          <div>
            <span style="font-size:18px;color:#333333;font-weight:bold;margin-right:20px">{{item.title}}</span>
            <Tag size="large" :color="item.type == 2?'success':item.type == 1?'orange':'blue'">{{type_fair(item.type)}}</Tag>

          </div>
          <div class="_box">
            <div class="content">
              <div class="con">
                <p>日期：<span>{{item.day}}</span></p>
                <p>联系人：<span>{{item.contacts}}</span></p>
                <p>地点：<span>{{item.address}}</span></p>
                <p v-if="item.status == 2" style="color:#f90">名额已满，报名招聘会请联系客服。</p>
              </div>
              <div class="con">
                <p>时间：<span>{{item.time}}</span></p>
                <p>手机：<span>{{item.telephone}}</span></p>
              </div>

            </div>

            <div class="but">
              <Button :type="item.status?'dashed':'warning'" :disabled="item.status?true:false" @click="go_apply(item._id,item.publicLevel)">{{item.status?"停止报名":"报名参加"}}</Button>

            </div>
          </div>
          <hr>
        </li>

      </ul>
    </section>
    <footer id="abc">
      <p class="text">|&nbsp;招聘会列表</p>
      <Table :columns="columns1" :data="Tabledata">

        <template slot-scope="{ row }" slot="status">
          <p :style="`color:${row.status==1?'#767676':row.status==2?'#f26464':'#00b42a'}`">{{row.status==1?'正在审核，请您耐心等待，我们的工作人员会尽快联系您':row.status==2?'审核不通过，请联系客服':'已通过审核，请准时参加招聘会'}}</p>
        </template>

        <!-- 1:审核中  2:不通过  3:通过 -->

        <template slot-scope="{ row }" slot="details">
          <div style="display:flex;justify-content:space-evenly">
            <Button type="warning" size="small" :disabled="row.status != 1" @click="$router.push({name:'job_fair_details_new',query:{id:row._id}})">修改招聘简章</Button>
            <Button type="primary" size="small" v-if="row.status == 1" @click="cancel_reserve(row._id)">取消预定</Button>
          </div>
        </template>
      </Table>
    </footer>
  </div>
</template>

<script>
export default {
  data() {
    return {
      //招聘会列表
      job_fair_list: [],
      //表格
      columns1: [
        {
          title: '招聘会举办日期',
          key: 'day',
          render: (h, params) => {
            return <span>{params.row.plan.day}<br />{params.row.plan.time}</span>
          },
          align: 'center',
        },
        {
          title: '招聘会名称',
          key: 'title',
          render: (h, params) => {
            return <span>{params.row.plan.title}</span>
          },
          align: 'center',
        },
        {
          title: '联系人',
          key: 'contacts',
          render: (h, params) => {
            return <span>{params.row.plan.contacts}</span>
          },
          align: 'center',
        },
        {
          title: '手机',
          key: 'telephone',
          render: (h, params) => {
            return <span>{params.row.plan.telephone}</span>
          },
          align: 'center',
        },
        {
          title: '状态',
          slot: 'status',
          align: 'center',
        },
        {
          title: '操作',
          slot: 'details',
          align: 'center',
        },

      ],
      Tabledata: []
    }
  },

  computed: {
    type_fair() {
      return (type) => {
        return type == 1 ? "网络" : type == 2 ? "室内" : '户外'
      }
    }
  },
  props: ['firm_data'],
  methods: {
    //滚动到表单
    select() {
      document.querySelector("#abc").scrollIntoView(true);
    },
    //报名参加招聘会
    go_apply(_id, publicLevel) {
      console.log(this.firm_data);
      console.log(publicLevel);
      if (publicLevel == 1 && this.firm_data.type == 1) {
        this.$Modal.warning({
          title: '该招聘会非会员无法参加',
        });
      } else {
        //获取可否报名资格(后端判断报名与否及企业资料完善与否)
        this.app("enterpriseOperators", "getOrderCondition", {
          mobile: localStorage.getItem("firm_mobile")
        }).then(res => {
          console.log(res.data);
          if (res.data) {
            this.$router.push({ name: "job_fair_details_new" })
            localStorage.setItem("planId", _id)
          } else {
            this.$Modal.warning({
              title: res.message,
            });
          }
        })
      }







    },
    //取消预定
    cancel_reserve(_id) {
      console.log(_id);
      this.$Modal.confirm({
        title: '确定取消参加该招聘会吗？',
        onOk: () => {
          this.app("enterpriseOperators", "removeJobFairOrder", { _id, }).then(res => {
            console.log(res);
            if (res.code == 200) {
              this.$Message.info('成功取消预定');
              this.get_ul_data()
              this.get_tab_data()
            }
          })
        },
      });
    },




    //封装获取ul数据
    get_ul_data() {
      this.app("enterpriseOperators", "getMonthPlans", {
        page: 1,
        size: 10,
        mobile: localStorage.getItem("firm_mobile")
      }).then(res => {
        console.log('li数据', res);
        this.job_fair_list = res.data
      })
    },
    // 封装获取表格数据
    get_tab_data() {
      this.app("enterpriseOperators", "myJobFairOrdersList", {
        mobile: localStorage.getItem("firm_mobile"),
        page: 1,
        size: 10
      }).then(res => {
        console.log('表格', res)
        this.Tabledata = res.data
      })
    },


  },
  created() {

    this.get_ul_data()
    this.get_tab_data()

  }
}
</script>

<style lang="less" scoped>
@import "./job_fair_new.less";
</style>
